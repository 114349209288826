/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo_dark from "../assets/img/coolhouse_logo.svg";
import logo from "../assets/img/coolhouse_logo_dark.svg";
import { Bar, Close } from "../Icon";
const Header = ({domovRef, oNasRef, ponudbaRef, mnenjaRef, kontaktRef}) => {
	const [open, setOpen] = useState(false);
	const [sticky, setSticky] = useState(false);

	const handleScroll = () => {
		const position = window.pageYOffset;
		if (position < 1) {
			setSticky(false);
		} else {
			setSticky(true);
		}
	};
	if (typeof window !== "undefined") {
		window.addEventListener("scroll", handleScroll);
	}

	// const navbarScroll = (ref) => {
	// 	window.scrollTo({
	// 	  top: ref.offsetTop,
	// 	  left: 0,
	// 	  behavior: "smooth",
	// 	});
	// };

	return (
		<header className={`header-section ${sticky ? "sticky" : ""}`}>
			<div className="container">
				<div className="header-wrapper">
					{/* <a href="#" className="logo"> */}
					<div className="logo">
						<img style={{width:"100%", maxHeight: "50px", objectFit: "scale-down"}} src={logo_dark} alt="" />
					</div>
					{/* </a> */}
					{/* <div className="d-md-none" onClick={() => setOpen(!open)}>
						{open ? (
							<Close color={sticky ? "#000" : "#fff"} />
						) : (
							<Bar color={sticky ? "#000" : "#fff"} />
						)}
					</div> */}
					{/* <ul className={`menu ${open ? "open" : ""}`}>
						<li>
							<a href="#" onClick={() => {navbarScroll(domovRef.current);}}>Domov</a>
						</li>
						<li>
							<a href="#o-nas" onClick={() => {navbarScroll(oNasRef.current);}}>O nas</a>
						</li>
						<li>
							<a href="#ponudba" onClick={() => {navbarScroll(ponudbaRef.current);}}>Ponudba</a>
						</li>
						<li>
							<a href="#mnenja" onClick={() => {navbarScroll(mnenjaRef.current);}}>Mnenja</a>
						</li>
						<li>
							<a href="#kontakt" onClick={() => {navbarScroll(kontaktRef.current);}}>Kontakt</a>
						</li>
					</ul> */}
				</div>
			</div>
		</header>
	);
};

export default Header;
