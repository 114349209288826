/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import img1 from "../assets/img/layer1.png";
import img2 from "../assets/img/layer2.png";

const LayerSection = () => {
	return (
		<section className="layer-section pt-120 pb-120 bg--title">
			<div className="row gx-0 align-items-center">
				<div className="col-lg-6">
					<img src={img1} className="mw-100" alt="" />
				</div>
				<div className="col-lg-6">
					<LayerContent />
				</div>
			</div>
			<div className="pt-120 pt-max-lg-45px">
				<div className="row gx-0 flex-row-reverse align-items-center">
					<div className="col-lg-6 text-end">
						<img src={img2} className="mw-100" alt="" />
					</div>
					<div className="col-lg-6">
						<LayerContent2 />
					</div>
				</div>
			</div>
		</section>
	);
};

export const LayerContent = () => {
	const myRef = useRef();
	const { inViewport } = useInViewport(myRef);
	return (
		<div
			className={`layer-content px-4 ${inViewport ? "anime" : ""}`}
			ref={myRef}
		>
			<div className="content">
				<a href="#" className="btn btn-sm-red mb-5">
					-25% OFF
				</a>
				<h2 className="title">
					Vrhunski izdelki v ponudbi
				</h2>
				<h6>Ne spreglejte posebne ponudbe</h6>
				<a href="#" className="btn btn-base">
					Prijava na e-novice
				</a>
			</div>
		</div>
	);
};
export const LayerContent2 = () => {
	const myRef = useRef();
	const { inViewport } = useInViewport(myRef);
	return (
		<div
			className={`layer-content px-4 ms-auto ${inViewport ? "anime" : ""}`}
			ref={myRef}
		>
			<div className="content">
				<a href="#" className="btn btn-sm-red mb-5">
					-25% OFF
				</a>
				<h2 className="title">Varen in enostaven nakup</h2>
				{/* <h6>Just add some of our juicy side dishes</h6> */}
				<h6></h6>
				<a href="#" className="btn btn-base">
					Preglej ponudbo
				</a>
			</div>
		</div>
	);
};

export default LayerSection;
