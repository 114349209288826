/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const OfferItem = ({ img, title, price, center, text }) => {
	return (
		<div className={`card-body d-flex flex-column offer-item ${center ? "text-center" : ""}`}>
			<div className="text-center">
				<img src={img} alt="" />
			</div>
			<h4 className="title">
				{/* <a href="#">{title}</a> */}
				<Link to="/productDetails">{title}</Link>
			</h4>
			{text && <p>{text}</p>}
			<a href="#" className="btn btn-sm-red mt-auto align-self-start">
				{price}
			</a>
		</div>
	);
};

export default OfferItem;
