/* eslint-disable jsx-a11y/anchor-is-valid */
import { Routes, Route } from 'react-router-dom';
import { useState } from "react";
import "./App.scss";
import { ArrowUp } from "./Icon";
import Home from "./pages/Home";
import Landing from "./pages/Landing"
import Izdelki from "./pages/Izdelki";
import ProductDetails from "./pages/ProductDetails";
import Loading from "./pages/Loading";
import { useEffect } from 'react';

function App() {

	// const [sticky, setSticky] = useState(false);

	// const handleScroll = () => {
	// 	const position = window.pageYOffset;
	// 	if (position < 200) {
	// 		setSticky(false);
	// 	} else {
	// 		setSticky(true);
	// 	}
	// };
	// if (typeof window !== "undefined") {
	// 	window.addEventListener("scroll", handleScroll);
	// }

	//----------------------Loading screen logic----------------
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 18000);
	}, []);
	//----------------------------------------------------------

	return (
		<div className="App">
			{/* {loading ? (<Loading />) : */}
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/izdelki" element={<Izdelki />} />
					<Route path="landing" element={<Landing />}/>
					{/* {sticky && (
						<a href="#" className="scroll-top">
							<ArrowUp />
						</a>
					)} */}
					<Route path="/izdelki/:naziv" element={<ProductDetails />} />
				</Routes>	
			{/* } */}
		</div>
	);
}

export default App;