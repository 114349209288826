import React, { useState, useEffect } from 'react';
import logo_dark from "../assets/img/coolhouse_logo.svg";


const Loading = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     // Simulating an asynchronous operation
//     setTimeout(() => {
//       setIsLoading(false);
//     }, 2000);
//   }, []);

  return (
    <div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <img  src= { logo_dark } alt="Centered Image" style={{ width:"100%", maxHeight: "50px", objectFit: "scale-down" }} />
            {/* <h1>Raj za foodije</h1> */}
         </div>
    </div>
  );
};

export default Loading;
