/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";
import Slider from "react-slick";
import img from "../assets/img/clients.jpg";
import { Quotes } from "../Icon";
const ClientsSay = ({mnenjaRef}) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		margin: 30,
	};
	const myRef = useRef();
	const { inViewport } = useInViewport(myRef);
	return (
		<section ref={mnenjaRef}  className="clients-say pb-120">
			<div className="container-fluid">
				<div className="row align-items-center gy-5">
					<div className="col-md-6">
						<div className="me-xl-5">
							<img
								src={img}
								className="w-100"
								style={{ borderRadius: "20px" }}
								alt=""
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div
							className={`about-content ${inViewport ? "anime" : ""}`}
							style={{ maxWidth: "530px", margin: "0" }}
							ref={myRef}
						>
							<a href="#" className="btn btn-sm-black">
								MNENJA
							</a>
							<h2 className="title">Kaj o nas menijo naše stranke?</h2>
							<Slider {...settings}>
								<SlideItem1 />
								<SlideItem2 />
								<SlideItem3 />
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const SlideItem1 = () => {
	return (
		<div>
			<div className="client-content pt-xl-4 pt-xxl-5">
				<div className="mb-3">
					<Quotes />
				</div>
				<p>
					Odlična izbira vrhunskega mesa, posebej navdušen nad Ribeye Black Angus! 
				</p>
			</div>
			<h5 className="mt-lg-5">-Marcel D.</h5>
		</div>
	);
};
const SlideItem2 = () => {
	return (
		<div>
			<div className="client-content pt-xl-4 pt-xxl-5">
				<div className="mb-3">
					<Quotes />
				</div>
				<p>
					Simpatična trgovinica, ki ima veliko ponudbo predvsem italijanske hrane, ki jo je nekoliko težje dobiti. 
				</p>
			</div>
			<h5 className="mt-lg-5">-Matjaž B.</h5>
		</div>
	);
};
const SlideItem3 = () => {
	return (
		<div>
			<div className="client-content pt-xl-4 pt-xxl-5">
				<div className="mb-3">
					<Quotes />
				</div>
				<p>
					Kdor ima rad morske dobrote naj pride v Coolhouse.
				</p>
			</div>
			<h5 className="mt-lg-5">-Toni N.</h5>
		</div>
	);
};
export default ClientsSay;
