import React, { useRef } from "react";
import About from "../components/About";
import Banner from "../components/Banner";
import ClientsSay from "../components/ClientsSay";
import DesertMenu from "../components/DesertMenu";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Insta from "../components/Insta";
import LayerSection from "../components/LayerSection";
import SpecialOffers from "../components/SpecialOffers";
import TrendingBurgers from "../components/TrendingBurgers";
import Subscribe from "../components/Modal";
import { ArrowUp } from "../Icon";
import { useEffect, useState } from "react";

const Home = () => {
	const domovRef = useRef(null);
	const oNasRef = useRef(null);
	const ponudbaRef = useRef(null);
	const mnenjaRef = useRef(null);
	const kontaktRef = useRef(null);
	const [showModal, setShowModal] = useState(false);

	const [sticky, setSticky] = useState(false);

	const handleScroll = () => {
		const position = window.pageYOffset;
		if (position < 200) {
			setSticky(false);
		} else {
			setSticky(true);
		}
	};
	if (typeof window !== "undefined") {
		window.addEventListener("scroll", handleScroll);
	}

	useEffect(() => {
		window.location.replace("https://coolhouse.si/")
		// setTimeout(() => setShowModal(true), 3000);
	}, []);

	return (
		<>
			{/* <Header domovRef={domovRef} oNasRef={oNasRef} ponudbaRef={ponudbaRef} mnenjaRef={mnenjaRef} kontaktRef={kontaktRef}/>
			<Banner domovRef={domovRef}/>
			<Subscribe showModal={showModal} setShowModal={setShowModal}/>
			<About oNasRef={oNasRef}/>
			<SpecialOffers />
			<LayerSection />
			<TrendingBurgers ponudbaRef={ponudbaRef}/>
			<DesertMenu />
			<ClientsSay mnenjaRef={mnenjaRef}/>
			<Insta />
			<Footer kontaktRef={kontaktRef}/>
			{sticky && (
					<a href="#" className="scroll-top">
						<ArrowUp />
					</a>
			)} */}
		</>
	);
};

export default Home;
