import React from "react";
import img1 from "../assets/img/offers/burger1.jpg";
import img2 from "../assets/img/offers/burger2.jpg";
import img3 from "../assets/img/offers/burger3.jpg";
import img4 from "../assets/img/offers/burger4.jpg";
import tiramisu from "../assets/img/offers/tiramisu.png";
import olje from "../assets/img/offers/olje.png";
import vino from "../assets/img/offers/vino.png";
import pasta from "../assets/img/offers/pasta.png";
import meso from "../assets/img/offers/meso.png";
import losos from "../assets/img/offers/losos.png"
import zrezek from "../assets/img/offers/zrezek.jpg";
import ostalo from "../assets/img/offers/ostalo.png";
import ribe from "../assets/img/offers/ribe.png";
import pršut from "../assets/img/offers/pršut.jpg";
import OfferItem from "./OfferItem";

const TrendingBurgers = ({ponudbaRef}) => {
	return (
		<div ref={ponudbaRef} className="pt-120 pb-120 trending-menu-section">
			<div className="container-fluid">
				<h2 className="title mb-5">Spletna prodaja</h2>
				<div className="row g-3 g-lg-4">
					{data?.map((item, i) => (
					<div className="col-sm-6 col-lg-3 d-flex" key={i}>
							<div className="card-body offer-item text-center">
								{/* <Link to={`${item.ime}`} state={{ime: item.ime, opis: item.opis, slika: item.slika}} > */}
									<div className="text-center">
									<img style={{ border: "3px solid",  borderColor: "#595858"}} src={item.img} alt="" />
									</div>
									<h4 className="title">
										{item.title}
									</h4>
									<div className="btn btn-sm-red mt-auto align-self-start">
										Več
									</div>
								{/* </Link> */}
							</div>
					</div>
					))}
				</div>
			</div>
		</div>
	);
};

const data = [
	{
		img: zrezek,
		title: "Meso",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: losos,
		title: "Morska hrana",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: vino,
		title: "Vino",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: pasta,
		title: "Testenine",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: pršut,
		title: "Siri, pršuti",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: olje,
		title: "Olja in kisi",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: tiramisu,
		title: "Sladice",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
	{
		img: ostalo,
		title: "Ostalo",
		price: "$16",
		// text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
	},
];

export default TrendingBurgers;
