/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import img1 from "../assets/img/offers/1.jpg";
import img2 from "../assets/img/offers/2.jpg";
import img3 from "../assets/img/offers/3.jpg";
import img4 from "../assets/img/offers/4.jpg";
import special_offer from "../assets/img/offers/main.jpg";
import Social from "./SocialIcons";
import { PlusIcon } from "../Icon";
import OfferItem from "./OfferItem";
import { db, ref, onValue, get } from "../firebase";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../pages/Loading";
import Header from "../components/ProductHeader";
import Footer from "../components/ProductFooter";

const SpecialOffers = () => {
	// const location = useLocation()
	let { naziv } = useParams();
	// const { ime } = location.state
	// const { opis } = location.state
	// const { slika } = location.state
	const [item, setItem] = useState();
	const [loading, setLoading] = useState(true);

	const domovRef = useRef(null);
	const oNasRef = useRef(null);
	const ponudbaRef = useRef(null);
	const mnenjaRef = useRef(null);
	const kontaktRef = useRef(null);

	useEffect(() => {
		const izdelkiRef = ref(db, `izdelki/`);
		  onValue(izdelkiRef, (snapshot) => {
			snapshot.forEach((groupSnapshot) => {
			  let el = groupSnapshot.val();
			  if(el.ime == naziv){
				setItem(el);
			  }
			});
			setLoading(false);
		  });
	  }, []);

	return (
		<>
		{loading ? (
			<Loading />
		  ) : (
			<>
				<Header domovRef={domovRef} oNasRef={oNasRef} ponudbaRef={ponudbaRef} mnenjaRef={mnenjaRef} kontaktRef={kontaktRef}/>
				<section className="insta-section pb-120 pt-180">
					<div className="container">
						{/* <h2 className="title mb-4 mb-lg-5 pb-lg-4">Special offers</h2> */}
						<div className="row gy-5">
							<div className="col-lg-6">
								{/* <a href="#" className="btn btn-sm-red mb-5">
									New in town
								</a> */}
								<div className="special-offer-main">
									<img style={{ display: "block", marginLeft: "auto", marginRight: "auto", border: "3px solid",  borderColor: "#595858"}} src={item.slika} alt="" />
									{/* <h3>Ham Sandwich</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit,
										sed do eiusmod tempor incididunt ut labore et dolore
										magna aliqua. Quis ipsum suspendisse ultrices gravida.
									</p>
									<div
										className="d-flex flex-wrap mb-3 pt-2"
										style={{ gap: "5px" }}
									>
										<a href="#" className="btn btn-outline">
											fat 26g
										</a>
										<a href="#" className="btn btn-outline">
											sugar 34g
										</a>
										<a href="#" className="btn btn-outline">
											carbs 26g
										</a>
									</div>
									<div
										className="d-flex flex-wrap justify-content-between align-items-center"
										style={{ rowGap: "10px" }}
									>
										<h4 className="m-0">$14.00</h4>
										<div className="btn btn-md-black">
											<PlusIcon /> Learn More
										</div>
									</div> */}
								</div>
							</div>
							<div className="col-lg-6">
					<div className="container">
						<div className="about-content" style={{textAlign:"left"}}>
							<a href="#" className="btn btn-sm-black">
								OPIS
							</a>
							<h2 className="title">
								{item.ime}
							</h2>
							{/* <p> */}
							<div style={{textAlign:"justify"}}>
								{item.opis}
							</div>
							<br />
							{/* </p> */}
							{/* <p>
								V CoolHouse-u lahko izbirate med različnimi vrstami sendvičev,
								ki so pripravljeni iz različnih vrst mesa, zelenjave in
								sestavin za pripravo omak. Vse sendviče lahko pripravimo
								tudi brez mesa.
							</p> */}
							<p>
								<h6>Deli z ostalimi: </h6> <Social naziv={naziv}/>
							</p>
						</div>
					</div>
							</div>
						</div>
					</div>
				</section>
				<Footer kontaktRef={kontaktRef}/>
			</>
		  )}
		  </>
	);
};

const data = [
	{
		img: img1,
		title: "Juicy Beef Burger Meal",
		price: "$16",
	},
	{
		img: img2,
		title: "Choko Milkshake From Heaven",
		price: "$16",
	},
	{
		img: img3,
		title: "Blueberry Waffles",
		price: "$16",
	},
	{
		img: img4,
		title: "Medium Portion of Fries",
		price: "$16",
	},
];

export default SpecialOffers;
