export const Bar = ({ color }) => {
	return (
		<svg width={31} height={30} viewBox="0 0 31 30" fill="none">
			<g clipPath="url(#clip0_3_218)">
				<path
					d="M22.8193 22.5V25H7.81934V22.5H22.8193ZM26.5693 13.75V16.25H4.06934V13.75H26.5693ZM22.8193 5V7.5H7.81934V5H22.8193Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_3_218">
					<rect
						width={30}
						height={30}
						fill={color}
						transform="translate(0.319336)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const Close = ({ color }) => {
	return (
		<>
			<svg width={18} x={0} y={0} viewBox="0 0 512 512">
				<g>
					<g id="_02_User" data-name="02 User">
						<path
							d="m25 512a25 25 0 0 1 -17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462a24.93 24.93 0 0 1 -17.68 7.32z"
							fill={color}
							data-original={color}
						/>
						<path
							d="m487 512a24.93 24.93 0 0 1 -17.68-7.32l-462-462a25 25 0 0 1 35.36-35.36l462 462a25 25 0 0 1 -17.68 42.68z"
							fill={color}
							data-original={color}
						/>
					</g>
				</g>
			</svg>
		</>
	);
};

export const PlusIcon = () => (
	<svg
		width={44}
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g>
			<path
				d="M28 22.75H16C15.59 22.75 15.25 22.41 15.25 22C15.25 21.59 15.59 21.25 16 21.25H28C28.41 21.25 28.75 21.59 28.75 22C28.75 22.41 28.41 22.75 28 22.75Z"
				fill="#fff"
			/>
			<path
				d="M22 28.75C21.59 28.75 21.25 28.41 21.25 28V16C21.25 15.59 21.59 15.25 22 15.25C22.41 15.25 22.75 15.59 22.75 16V28C22.75 28.41 22.41 28.75 22 28.75Z"
				fill="#fff"
			/>
		</g>
	</svg>
);

export const Quotes = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			width={48}
			x={0}
			y={0}
			viewBox="0 0 512 512"
			xmlSpace="preserve"
			className
		>
			<g>
				<path
					d="M119.5 78.4c15.6.1 28.1 12.8 28.1 28.3v35.7c.1 10.9-6 20.8-15.8 25.6-21 10-33.6 29.6-38.4 59.8-2 12.7-3.3 25.2-4.6 38.5-.5 5.7-1.1 11.7-1.8 17.7-.7 6.6 4 12.5 10.6 13.2.4 0 .9.1 1.4.1h71c23 0 41.6 18.7 41.7 41.7v53c0 23-18.7 41.6-41.7 41.7H54.9c-23.6 0-42.7-19.1-42.8-42.7-.1-23.5-.2-82.2 0-118.9.3-47.1 5-82.6 15.2-115 14.1-44.8 42.5-70.3 86.9-78.2l.4-.1c1.7-.2 3.3-.4 4.9-.4m0-12c-2.3 0-4.6.2-6.9.6l-.5.1c-49.8 8.8-81.2 38.7-96.2 86.4C3.8 192.1.4 231.9.1 272c-.2 30.9-.1 80.6 0 119 .1 30.2 24.6 54.6 54.8 54.6H170c29.6 0 53.7-24 53.7-53.7v-53c0-29.6-24-53.7-53.6-53.7H99c2.1-19.3 3.4-37.6 6.3-55.7 3.7-23.2 12.7-41.7 31.7-50.8 13.9-6.8 22.7-20.9 22.6-36.4v-35.7c0-22.1-17.9-40.1-40.1-40.2zM407.9 78.4c15.6.1 28.1 12.8 28 28.3v35.7c.1 10.9-6 20.8-15.8 25.6-21 10-33.6 29.6-38.4 59.8-2 12.7-3.3 25.2-4.6 38.5-.6 5.8-1.2 11.8-1.8 17.8-.7 6.6 4 12.5 10.6 13.2.4 0 .9.1 1.3.1h71.1c23 0 41.6 18.7 41.7 41.6v53c0 23-18.7 41.6-41.7 41.7h-115c-23.6 0-42.7-19.1-42.8-42.7-.1-23.5-.2-82.2 0-118.9.3-47.1 5-82.6 15.2-115 14.1-44.8 42.5-70.3 86.9-78.2l.4-.1c1.6-.2 3.2-.4 4.9-.4m0-12c-2.3 0-4.7.2-7 .6l-.5.1c-49.8 8.8-81.2 38.7-96.2 86.4-12.2 38.6-15.5 78.4-15.8 118.6-.2 30.9-.1 80.6 0 119 .1 30.2 24.6 54.6 54.8 54.6h115.1c29.6 0 53.7-24 53.7-53.6V339c0-29.6-24-53.7-53.6-53.7h-71.1c2.1-19.3 3.4-37.6 6.3-55.7 3.7-23.2 12.7-41.8 31.7-50.8 13.9-6.8 22.7-20.9 22.6-36.4v-35.7c0-22.2-17.9-40.2-40-40.3z"
					fill="var(--base)"
					data-original="var(--base)"
					className
				/>
			</g>
		</svg>
	);
};
export const Facebook = () => {
	return (
		<>
			<svg width={9} viewBox="0 0 9 16" fill="none">
				<path
					d="M2.90624 16V8.84373H0.5V5.99999H2.90624V3.74999C2.90624 2.56249 3.23958 1.64062 3.90624 0.984373C4.57291 0.328124 5.45832 0 6.56249 0C7.45832 0 8.18748 0.0416664 8.74998 0.125V2.65624H7.24998C6.68749 2.65624 6.30207 2.78124 6.09374 3.03124C5.92707 3.23958 5.84374 3.57291 5.84374 4.03124V5.99999H8.49998L8.12498 8.84373H5.84374V16H2.90624Z"
					fill="var(--title)"
				/>
			</svg>
		</>
	);
};
export const Instagram = () => {
	return (
		<>
			<svg
				width="14"
				height="13"
				viewBox="0 0 14 13"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.125 3.60547C5.375 3.60547 3.98047 5.02734 3.98047 6.75C3.98047 8.5 5.375 9.89453 7.125 9.89453C8.84766 9.89453 10.2695 8.5 10.2695 6.75C10.2695 5.02734 8.84766 3.60547 7.125 3.60547ZM7.125 8.80078C6.00391 8.80078 5.07422 7.89844 5.07422 6.75C5.07422 5.62891 5.97656 4.72656 7.125 4.72656C8.24609 4.72656 9.14844 5.62891 9.14844 6.75C9.14844 7.89844 8.24609 8.80078 7.125 8.80078ZM11.1172 3.49609C11.1172 3.90625 10.7891 4.23438 10.3789 4.23438C9.96875 4.23438 9.64062 3.90625 9.64062 3.49609C9.64062 3.08594 9.96875 2.75781 10.3789 2.75781C10.7891 2.75781 11.1172 3.08594 11.1172 3.49609ZM13.1953 4.23438C13.1406 3.25 12.9219 2.375 12.2109 1.66406C11.5 0.953125 10.625 0.734375 9.64062 0.679688C8.62891 0.625 5.59375 0.625 4.58203 0.679688C3.59766 0.734375 2.75 0.953125 2.01172 1.66406C1.30078 2.375 1.08203 3.25 1.02734 4.23438C0.972656 5.24609 0.972656 8.28125 1.02734 9.29297C1.08203 10.2773 1.30078 11.125 2.01172 11.8633C2.75 12.5742 3.59766 12.793 4.58203 12.8477C5.59375 12.9023 8.62891 12.9023 9.64062 12.8477C10.625 12.793 11.5 12.5742 12.2109 11.8633C12.9219 11.125 13.1406 10.2773 13.1953 9.29297C13.25 8.28125 13.25 5.24609 13.1953 4.23438ZM11.8828 10.3594C11.6914 10.9062 11.2539 11.3164 10.7344 11.5352C9.91406 11.8633 8 11.7812 7.125 11.7812C6.22266 11.7812 4.30859 11.8633 3.51562 11.5352C2.96875 11.3164 2.55859 10.9062 2.33984 10.3594C2.01172 9.56641 2.09375 7.65234 2.09375 6.75C2.09375 5.875 2.01172 3.96094 2.33984 3.14062C2.55859 2.62109 2.96875 2.21094 3.51562 1.99219C4.30859 1.66406 6.22266 1.74609 7.125 1.74609C8 1.74609 9.91406 1.66406 10.7344 1.99219C11.2539 2.18359 11.6641 2.62109 11.8828 3.14062C12.2109 3.96094 12.1289 5.875 12.1289 6.75C12.1289 7.65234 12.2109 9.56641 11.8828 10.3594Z"
					fill="var(--title)"
				/>
			</svg>
		</>
	);
};
export const Twitter = () => {
	return (
		<>
			<svg width={17} viewBox="0 0 17 16" fill="none">
				<path
					d="M14.8437 5.24999C14.8645 5.33333 14.875 5.46874 14.875 5.65624C14.875 7.15624 14.5104 8.60415 13.7812 9.99998C13.0312 11.4791 11.9791 12.6562 10.625 13.5312C9.14581 14.5104 7.4479 15 5.53124 15C3.69791 15 2.02083 14.5104 0.5 13.5312C0.729166 13.5521 0.989582 13.5625 1.28125 13.5625C2.80208 13.5625 4.16666 13.0937 5.37499 12.1562C4.64582 12.1562 4.0052 11.9427 3.45312 11.5156C2.90104 11.0885 2.52083 10.5521 2.3125 9.90623C2.52083 9.92706 2.71874 9.93748 2.90624 9.93748C3.19791 9.93748 3.48958 9.90623 3.78124 9.84373C3.03124 9.67707 2.40625 9.29165 1.90625 8.68748C1.40625 8.08332 1.15625 7.39582 1.15625 6.62499V6.56249C1.61458 6.83332 2.10416 6.97915 2.62499 6.99999C2.1875 6.68749 1.83333 6.29166 1.5625 5.81249C1.29166 5.33333 1.15625 4.80728 1.15625 4.23437C1.15625 3.66145 1.3125 3.11458 1.625 2.59375C2.4375 3.61458 3.43228 4.42708 4.60936 5.03124C5.78645 5.63541 7.04165 5.96874 8.37498 6.03124C8.33331 5.78124 8.31248 5.53124 8.31248 5.28124C8.31248 4.69791 8.45831 4.15104 8.74998 3.64062C9.04165 3.13021 9.43748 2.72916 9.93748 2.4375C10.4375 2.14583 10.9791 2 11.5625 2C12.0416 2 12.4843 2.09375 12.8906 2.28125C13.2968 2.46875 13.6562 2.71875 13.9687 3.03125C14.7187 2.88541 15.4166 2.625 16.0625 2.25C15.8125 3.02083 15.3333 3.625 14.625 4.06249C15.25 3.97916 15.875 3.80208 16.5 3.53125C16.0416 4.19791 15.4895 4.77083 14.8437 5.24999Z"
					fill="var(--title)"
				/>
			</svg>
		</>
	);
};
export const MapMarker = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth="{1.5}"
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
				/>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
				/>
			</svg>
		</>
	);
};
export const Phone = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth={1.5}
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
				/>
			</svg>
		</>
	);
};
export const Envelop = () => {
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth={1.5}
				stroke="currentColor"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
				/>
			</svg>
		</>
	);
};
export const ArrowUp = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M4.5 15.75l7.5-7.5 7.5 7.5"
		/>
	</svg>
);
