import React, { useState }  from "react";
import logo from "../assets/img/coolhouse_logo.svg";
import { Envelop, MapMarker, Phone } from "../Icon";
import SocialIcons from "./SocialIcons";

const Footer = ({kontaktRef}) => {
	const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	//zapri obvestilo, če je bil mail uspešno ali neuspešno dodan
	const [response, setResponse] = useState(true);
	const handleResponse = () => setResponse(false);

	const resetForm = () => {
		setEmail('');
		// setSuccess(false);
		// setError(false);
	};

	const handleSubmit = (e) => {
		setResponse(true);
		e.preventDefault();
		console.log('posiljam email: ' + email)
		var url = "https://spletnastran-54cda.ew.r.appspot.com"
		//post request to backend with response
		fetch(url, {
			method: 'POST',
			headers: {
			'Content-Type': 'application/json'
			},
			body: JSON.stringify({
			email: email
			})
		})
		.then(response => {
			console.log('response: ',response)
			if(response.ok == true) {
				setSuccess(true);
			} else {
				setError(true);
			}
			resetForm(); // Clear email field after form submission
		})
	   
		// push(ref(db, 'emails'), {
		//   email: email,
		// }).then(() => {
		//   console.log('Data saved successfully!');
		//   setSuccess(true);
		// })
		// .catch((error) => {
		//   console.log('Data could not be saved.' + error);
		//   setError(true);
		// });   
	  }

	return (
		<footer ref={kontaktRef} className="pb-120 pt-120">
			<div className="container">
				<div className="footer-wrapper">
					{/* <div className="footer-widget about-area">
						<img src={logo} className="logo" alt="" />
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit,
							sed do
						</p>
						<SocialIcons />
					</div> */}
					<div className="footer-widget addr-area">
						<h4 className="subtitle">Trgovina Šiška</h4>
						<ul>
							<li>
								<MapMarker /> Celovška 268, 1000 Ljubljana
							</li>
							<li>
								<Phone /> 01 518 90 44
							</li>
							{/* <li>
								<Envelop /> trgovinavic@coolhouse.si{" "}
							</li> */}
							<li>
								PON - SOB I 9:00 - 20:00 <br />NED I zaprto
							</li>
						</ul>
					</div>
					<div className="footer-widget addr-area">
						<h4 className="subtitle">Trgovina Vič</h4>
						<ul>
							<li>
								<MapMarker /> Gerbičeva 120, 1000 Ljubljana
							</li>
							<li>
								<Phone /> 059340530
							</li>
							<li>
								<Envelop /> trgovinavic@coolhouse.si{" "}
							</li>
							<li>
								PON - SOB I 9:00 - 20:00 <br />NED I zaprto
							</li>
						</ul>
					</div>
					<div className="footer-widget subscribe-area text-center">
						<h4 className="subtitle">Prijava na e-novice</h4>
							<form className="subscribe-form mt-4">
								<input
									type="text"
									placeholder="Vpišite svoj e-mail naslov"
									className="form-control mb-3"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								{response && error && <div className="alert alert-danger alert-dismissible" role="alert">Neuspešno prijavljanje. Prosimo poskusite ponovno.
								<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleResponse}>
									<span aria-hidden="true">&times;</span>
								</button>
								</div>}   
								{response && success && <div className="alert alert-success alert-dismissible" role="alert">Uspešno ste se prijavili na e-novice.
								<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleResponse}>
									<span aria-hidden="true">&times;</span>
								</button>
								</div>}     
								<button type="submit" className="btn btn-base" onClick={handleSubmit}>
									Pošlji
								</button>
							</form>
						<div className="text-center mt-2">
							<small>S prijavo na e-novice boste obveščeni o novostih iz naše ponudbe.</small>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
