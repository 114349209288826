import React from "react";
import img1 from "../assets/img/insta/insta1.jpg";
import img2 from "../assets/img/insta/insta2.jpg";
import img3 from "../assets/img/insta/insta3.jpg";
import img4 from "../assets/img/insta/insta4.jpg";
const Insta = () => {
	return (
		<>
			<section className="insta-section pb-120">
				<div className="row g-0 gy-4">
					{data?.map((item, i) => (
						<div className="col-sm-6 col-md-3" key={i}>
							<img src={item} alt="" className="w-100" />
						</div>
					))}
				</div>
			</section>
		</>
	);
};
const data = [img1, img2, img3, img4];

export default Insta;
