/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import img1 from "../assets/img/offers/1.jpg";
import img2 from "../assets/img/offers/2.jpg";
import img3 from "../assets/img/offers/3.jpg";
import img4 from "../assets/img/offers/4.jpg";
import special_offer from "../assets/img/offers/main.jpg";
import { PlusIcon } from "../Icon";
import OfferItem from "./OfferItem";

const SpecialOffers = () => {

	return (
		<section className="special-offers pb-120">
			<div className="container">
				<h2 className="title mb-4 mb-lg-5 pb-lg-4">Posebna ponudba</h2>
				<div className="row gy-5">
					<div className="col-lg-6">
						<a href="#" className="btn btn-sm-red mb-5">
							Novo
						</a>
						<div className="special-offer-main">
							<img src={special_offer} alt="" />
							<h3>Ameriški jastog</h3>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit,
								sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Quis ipsum suspendisse ultrices gravida.
							</p>
							<div
								className="d-flex flex-wrap mb-3 pt-2"
								style={{ gap: "5px" }}
							>
								<a href="#" className="btn btn-outline">
									fat 26g
								</a>
								<a href="#" className="btn btn-outline">
									sugar 34g
								</a>
								<a href="#" className="btn btn-outline">
									carbs 26g
								</a>
							</div>
							<div
								className="d-flex flex-wrap justify-content-between align-items-center"
								style={{ rowGap: "10px" }}
							>
								<h4 className="m-0">$14.00</h4>
								<div className="btn btn-md-black">
									<PlusIcon /> Learn More
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="row g-3 g-lg-4">
							{data?.map((item, i) => (
								<div className="col-sm-6 d-flex align-items-stretch" key={i}>
									<OfferItem center {...item} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const data = [
	{
		img: img1,
		title: "Goveja pljučna",
		price: "$16",
	},
	{
		img: img2,
		title: "Tomahawk steak",
		price: "$16",
	},
	{
		img: img3,
		title: "Ekstra deviško oljčno olje",
		price: "$16",
	},
	{
		img: img4,
		title: "T-bone steak",
		price: "$16",
	},
];

export default SpecialOffers;
