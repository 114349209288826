import React, { useRef } from "react";
import Footer from "../components/ProductFooter";
import Header from "../components/ProductHeader";
import Product from "../components/Product";
import Loading from "./Loading";

const ProductDetails = () => {
	const domovRef = useRef(null);
	const oNasRef = useRef(null);
	const ponudbaRef = useRef(null);
	const mnenjaRef = useRef(null);
	const kontaktRef = useRef(null);

	return (
		<>
			{/* <Header domovRef={domovRef} oNasRef={oNasRef} ponudbaRef={ponudbaRef} mnenjaRef={mnenjaRef} kontaktRef={kontaktRef}/> */}
            <Product />
			{/* <Footer kontaktRef={kontaktRef}/> */}
		</>
	);
};

export default ProductDetails;