import React, { useEffect, useState } from "react";
import { Facebook, Instagram, Twitter } from "../Icon";

const social_icons = [
	// {
	// 	icon: <Instagram />,
	// 	link: "http://www.facebook.com/dialog/send?app_id=[app-id]&link=https://stackoverflow.com",
	// },
	{
		icon: <Facebook />,
		link: "https://www.facebook.com/sharer/sharer.php?u=coolhouse-skrinja.si/izdelki",
	},
	{
		icon: <Twitter />,
		link: "https://twitter.com/intent/tweet?text=coolhouse-skrinja.si/izdelki",
	},
];

const SocialIcons = ({naziv}) => {

	useEffect(()=>{
		console.log(naziv)
	})

	const [social, setSocial] = useState(social_icons);
	return (
		<>
			{/* <ul className={`social-icons ${className ? className : ""}`}> */}
			<ul className={`social-icons`}>
					<li >
						<a href="https://www.facebook.com/sharer/sharer.php?u=coolhouse-skrinja.si/izdelki/${naziv}"><Facebook/></a>
					</li>
					<li >
						<a href={`https://twitter.com/intent/tweet?text=coolhouse-skrinja.si/izdelki/${naziv}`}><Twitter/></a>
					</li>
			</ul>
		</>
	);
};

export default SocialIcons;
