// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, push, ref, set, get, child, onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwdYWXz29CMnsmAk6JqjGk0Xy7_GeGZUo",
  authDomain: "spletnastran-54cda.firebaseapp.com",
  databaseURL: "https://spletnastran-54cda-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "spletnastran-54cda",
  storageBucket: "spletnastran-54cda.appspot.com",
  messagingSenderId: "650182277989",
  appId: "1:650182277989:web:9e446aea309793580a7f8c",
  measurementId: "G-0E9DK0DSP9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
// const analytics = getAnalytics(app);

export { db, push, ref, set, get, child, onValue } 
