/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import banner_bg from "../assets/img/banner/banner-bg.jpg";
import banner_txt from "../assets/img/banner/banner-3d.png";
import banner from "../assets/img/banner/banner.png";
const Banner = ({domovRef}) => {
	return (
		<section
			ref={domovRef}
			className="banner-section"
			style={{
				background: `url(${banner_bg}) no-repeat center center / cover`,
			}}
		>
			<div className="container position-relative">
				<div className="banner-content">
					<div className="inner">
						<div className="txt-grp">
							<h3 className="subtitle">
								<img src={banner_txt} className="banner-txt" alt="" />
								<span className="text-base">RAJ</span>{" "}
								<span>ZA</span>
							</h3>
							<h1 className="title">FOODIJE</h1>
						</div>
						<img src={banner} alt="" className="banner_thumb" />
					</div>
				</div>
				{/* <div className="text-center">
					<a href="#" className="btn btn-base">
						See More
					</a>
				</div> */}
			</div>
		</section>
	);
};

export default Banner;
