import React, { useRef } from "react";
import About from "../components/About";
import Banner from "../components/Banner";
import ClientsSay from "../components/ClientsSay";
import DesertMenu from "../components/DesertMenu";
import Footer from "../components/ProductFooter";
import Header from "../components/ProductHeader";
import Insta from "../components/Insta";
import LayerSection from "../components/LayerSection";
import SpecialOffers from "../components/SpecialOffers";
import TrendingBurgers from "../components/TrendingBurgers";
import Okenca from "../components/Okenca";
import Subscribe from "../components/Modal";
import { ArrowUp } from "../Icon";
import { useEffect, useState } from "react";
import { db, ref, onValue, get } from "../firebase";
import { Link } from "react-router-dom";
import Loading from "./Loading";

const Home = () => {
	const domovRef = useRef(null);
	const oNasRef = useRef(null);
	const ponudbaRef = useRef(null);
	const mnenjaRef = useRef(null);
	const kontaktRef = useRef(null);
  
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [sticky, setSticky] = useState(false);
  
	const handleScroll = () => {
	  const position = window.pageYOffset;
	  if (position < 200) {
		setSticky(false);
	  } else {
		setSticky(true);
	  }
	};
  
	useEffect(() => {
		console.log("useEffect");
		const izdelkiRef = ref(db, `izdelki/`);
		const timeout = setTimeout(() => {
		  onValue(izdelkiRef, (snapshot) => {
			const tempData = [];
			snapshot.forEach((groupSnapshot) => {
			  let el = groupSnapshot.val();
			  tempData.push(el);
			});
			setData(tempData);
			setLoading(false);
		  });
		}, 1500);
	
		return () => clearTimeout(timeout);
	}, []);
  
	useEffect(() => {
	  if (typeof window !== "undefined") {
		window.addEventListener("scroll", handleScroll);
	  }
	  return () => {
		if (typeof window !== "undefined") {
		  window.removeEventListener("scroll", handleScroll);
		}
	  };
	}, []);
  
	return (
	  <>
	  				  {loading ? (
					<Loading />
				  ) : (
					<>
		<Header
		  domovRef={domovRef}
		  oNasRef={oNasRef}
		  ponudbaRef={ponudbaRef}
		  mnenjaRef={mnenjaRef}
		  kontaktRef={kontaktRef}
		/>
		<section className="insta-section pd-pb-180 pd-pt-180">
		  <div>
			{/* Okenca */}
			<div ref={ponudbaRef} className="trending-menu-section">
			  <div className="container-fluid">
				<div className="row g-3 g-lg-4">
					  {data.map((item, i) => (
						<div className="col-sm-6 col-lg-3 d-flex" key={i}>
								<div className="card-body offer-item text-center">
									<Link to={`${item.ime}`} state={{ime: item.ime, opis: item.opis, slika: item.slika}} >
										<div className="text-center">
										<img style={{ border: "3px solid",  borderColor: "#595858"}} src={item.slika} alt="" />
										</div>
										<h4 className="title">
											{item.ime}
										</h4>
										<div className="btn btn-sm-red mt-auto align-self-start">
											Več
										</div>
									</Link>
								</div>
						</div>
					  ))}
				</div>
			  </div>
			</div>
			{/* Okenca */}
			<Footer kontaktRef={kontaktRef} />
			{sticky && (
			<a href="#" className="scroll-top">
				<ArrowUp />
			</a>
			)}
		</div>
		</section>
		</>
				  )}
	  </>
	);
  };
  
  export default Home;