import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { db, push, ref, set } from '../firebase';

function Subscribe({ showModal, setShowModal}) {
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(true);
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleResponse = () => setResponse(false);
  const handleShow = () => setShow(true);

  const resetForm = () => {
    setEmail('');
    // setSuccess(false);
    // setError(false);
  };

  if(!showModal) {
    return null
  }

  const handleSubmit = (e) => {
    // setResponse(false);
    e.preventDefault();
    console.log('posiljam email: ' + email)
    var url = "https://spletnastran-54cda.ew.r.appspot.com"
    //post request to backend with response
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    })
    .then(response => {
      console.log('response: ',response)
      if(response.ok == true) {
        setSuccess(true);
      } else {
        setError(true);
      }
      // response.json()
      // .finally(() => {
        resetForm(); // Clear email field after form submission
      // });
    })
    

   //dodajanje v firebase
    // push(ref(db, 'emails'), {
    //   email: email,
    // }).then(() => {
    //   console.log('Data saved successfully!');
    //   setSuccess(true);
    // })
    // .catch((error) => {
    //   console.log('Data could not be saved.' + error);
    //   setError(true);
    // });   
  }

  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={showModal} onHide={handleClose} className='.modal-sm'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
		<Modal.Header closeButton>
          <Modal.Title className="subtitle">Prijava na e-novice</Modal.Title>
        </Modal.Header>
		<div className="footer-widget subscribe-area text-center" style={{padding:"10%"}}> 
			<h4 className="subtitle">Prejmite novice o tedenskih ugodnostih</h4>
			<form className="subscribe-form mt-4">
				<input
					type="text"
					placeholder="Vpišite svoj e-mail naslov"
					className="form-control mb-3"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
				/>
        {response && error && <div className="alert alert-danger alert-dismissible" role="alert">Neuspešno prijavljanje. Prosimo poskusite ponovno.
          {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleResponse}>
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>}   
        {response && success && <div className="alert alert-success alert-dismissible" role="alert">Uspešno ste se prijavili na e-novice.
          {/* <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleResponse}>
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>}     
				<button type="submit" className="btn btn-base" onClick={handleSubmit}>
					Pošlji
				</button>
			</form>
			<div className="text-center mt-2">
				<small>S prijavo na e-novice boste obveščeni o novostih iz naše ponudbe.</small>
			</div>
		</div>  
      </Modal>
    </div>
  );
}

export default Subscribe;