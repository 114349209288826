/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const About = ({oNasRef}) => {
	return (
		<section ref={oNasRef} className="about-section pt-120 pb-120">
			<div className="container">
				<div className="about-content">
					<a href="#" className="btn btn-sm-black">
						O NAS
					</a>
					<h2 className="title">
						Vaš kulinarični prijatelj
					</h2>
					<p>
					Nudimo širok nabor mesa in mesnih izdelkov, med njimi tudi telečje meso,
					goveje meso, jagnječe meso... Ponujamo vam širok nabor zamrznjenih celih rib ter filejev,
					dimljenih rib ter ostalo.
					</p>
					<h6>
					Fin-Tun že vrsto let tako kuharskim kot tudi slaščičarskim 
					mojstrom omogoča vrhunske kreacije in gastronomske rešitve.
					</h6>
				</div>
			</div>
		</section>
	);
};

export default About;
